<template>
  <!--  监测管理 -->
  <div class="monitoringManagement">
    <div class="toringMe">
      <div class="torinat">监测数据列表</div>
      <div class="monitor">
        <el-tabs v-model="activeName"
                 @tab-click="handleClick">
          <el-tab-pane label="已监测"
                       name="first"></el-tab-pane>
          <el-tab-pane label="未监测"
                       name="second"></el-tab-pane>
        </el-tabs>
        <el-table :data="childrenList"
                  v-el-table-infinite-scroll="tableLoad"
                  infinite-scroll-delay=300
                  infinite-scroll-disabled="tableLoadingDisabled"
                  header-row-class-name="table_header_class"
                  @selection-change="handleSelectionChange"
                  infinite-scroll-immediate="false"
                  :cell-style="rowClass"
                  :header-cell-style="{ textAlign: 'center' }"
                  stripe
                  :max-height="maxHeight"
                  height="625"
                  v-loading="tableLoading">
          <div slot="append"
               v-if="childrenList.length > 0"
               style="text-align: center;height:50px;line-height:50px">
            <p v-show="tableLoading">正在加载数据<i class="el-icon-loading"></i></p> <!-- 滚动加载时显示的内容 -->
            <p v-if="noTotalPage">没有更多数据了</p>
          </div>
          <el-table-column prop="name"
                           label="姓名">
          </el-table-column>
          <el-table-column prop="gradeName"
                           label="年级">
          </el-table-column>
          <el-table-column prop="className"
                           width="120"
                           label="班级">
          </el-table-column>
          <el-table-column prop="sex"
                           label="性别">
          </el-table-column>
          <el-table-column prop="phone"
                           label="手机号码"
                           width="120">
          </el-table-column>
          <el-table-column prop="idCard"
                           width="190"
                           label="身份证号">
          </el-table-column>
          <el-table-column :formatter="visionData1"
                           label="裸眼视力(右/左)"
                           width="140">
          </el-table-column>
          <el-table-column :formatter="visionData2"
                           label="戴镜视力(右/左)"
                           width="140">
          </el-table-column>
          <el-table-column :formatter="screeningNameData"
                           label="监视参与度">
          </el-table-column>
          <!-- <el-table-column>loading</el-table-column> -->
        </el-table>
      </div>
    </div>
    <div class="offside"
         v-show="roleType === 10 || roleType === 7">
      <div class="offside_s">
        <div class="nitorhe">
          <div class="headerSchool">
            <div class="header-link"></div>
            <p class="header-title">{{userSchoolName}}</p>
          </div>
          <div>动态监测间隔时效：</div>
          <el-radio-group v-model="MonigementInitRadio"
                          @change="helfChange">
            <el-radio :label="0">半个月</el-radio>
            <el-radio :label="1">一个月</el-radio>
          </el-radio-group>
          <div class="Choose_img">
            <i class="sl-cl">
              <img src="../../assets/detection/image/icon-r-1.png"
                   alt="">
            </i>
            选择时间：
          </div>
          <div class="block_s">
            <!-- 不滚动 -->
            <el-select v-model="value2"
                       placeholder="请选择"
                       class="demo-form-inline"
                       @change="timeChange"
                       v-scroll="handleScroll">
              <el-option v-for="item in timeTypeVoList"
                         :key="item.startTime"
                         :label="item.text"
                         :value="item.endTime">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="tect_bdf"
           v-show="showClass">已监测时长:<span class="tectedura">{{monitoringTime}}个月</span> </div>
      <div class="sideSelect">
        <div class="SelectGrade">
          <div class="Choose_img">
            <i class="uble-cl">
              <img src="../../assets/detection/image/icon-sge-1.png"
                   alt="">
            </i>
            选择年级
          </div>
          <el-select @change="editGrade"
                     v-model="getGradeId"
                     placeholder="请选择">
            <el-option v-for="item in gradelList"
                       :key="item.newGradeId"
                       :label="item.gradeName"
                       :value="item.newGradeId">
            </el-option>
          </el-select>
        </div>
        <div class="SelectGrade">
          <div class="Choose_img">
            <i class="uble-class">
              <img src="../../assets/detection/image/icon-class.png"
                   alt="">
            </i>
            选择班级
          </div>
          <el-select @change="editClass"
                     v-model="getClassId"
                     placeholder="请选择">
            <el-option v-for="item in schoolClassList"
                       :key="item.isClass"
                       :label="item.isClass"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="offside_z"
           v-show="pleber">
        <template v-if="roleType===10 || roleType===7 || roleType === 6">
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/icon-testNumbs.png"
                   alt="" />
            </div>
            <p style="color: #0E9CFF;">{{schoolRate.testNums}}</p>
            <span>已监测人数</span>
          </div>
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/icon-NumberOfPeopleWithVisualAbnormalities.png"
                   alt="" />
            </div>
            <p style="color: #FB9467;">{{schoolRate.abnormalNums}}</p>
            <span>视力异常人数</span>
          </div>
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/icon-VisualAcuityDiscrepancyRate.png"
                   alt="" />
            </div>
            <p style="color: #95C903;">{{AbnormalVisualVcuityRate}}</p>
            <span>视力异常率</span>
          </div>
        </template>
        <template v-else>
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/sxhool_1.png"
                   alt="" />
            </div>
            <p>{{schoolRate.totalNums}}</p>
            <span v-show="bertes">全校人数</span>
            <span v-show="!bertes">全班人数</span>
          </div>
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/tested_1.png"
                   alt="" />
            </div>
            <p>{{schoolRate.testNums}}</p>
            <span>已测人数</span>
          </div>
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/not_1.png"
                   alt="" />
            </div>
            <p>{{NumberOfPeopleToBeTested}}</p>
            <span>未测试人数</span>
          </div>
        </template>

      </div>
      <div class="pletobe"
           v-show="!pleber">
        <div class="NumberTested">
          <div class="bersd">
            <img src="../../assets/detection/image/renshu-1.png"
                 alt="" />
          </div>
          <p>{{NumberOfPeopleToBeTested}}</p>
          <span>待监测人数</span>
        </div>
      </div>
      <div class="offside_x">
        <div class="title_ser">
          <el-input placeholder="请输入学生姓名"
                    v-model="studentName"
                    @keyup.enter.native="studentSeach"
                    @clear="resetStudentSeach"
                    clearable>
          </el-input>
          <el-button type="primary"
                     @click="studentSeach"> 搜索<span class="gese"></span> <i class="el-icon-search el-icon--right"></i></el-button>
        </div>
      </div>
    </div>

    <div class="foot_def"
         v-show="roleType === 9">
      <div class="foot_s">
        <div class="nitorhe">
          <div class="ChooseSchool">
            <div class="Choose_img">
              <i class="uble-cl">
                <img src="../../assets/detection/image/icon-sch-1.png"
                     alt="">
              </i>
              选择学校
            </div>
            <el-select @change="editSchool"
                       v-model="getSchoolId"
                       placeholder="请选择">
              <el-option v-for="item in schoolList"
                         :key="item.id"
                         :label="item.schoolName"
                         :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div>动态监测间隔时效：</div>
          <el-radio-group v-model="MonigementInitRadio"
                          @change="helfChange">
            <el-radio :label="0">半个月</el-radio>
            <el-radio :label="1">一个月</el-radio>
          </el-radio-group>
          <div class="Choose_img">
            <i class="sl-cl">
              <img src="../../assets/detection/image/icon-r-1.png"
                   alt="">
            </i>
            选择时间：
          </div>
          <div class="block_s">
            <!-- 不滚动 -->
            <el-select v-model="value2"
                       placeholder="请选择"
                       class="demo-form-inline"
                       @change="timeChange"
                       v-scroll="handleScroll">
              <el-option v-for="item in timeTypeVoList"
                         :key="item.startTime"
                         :label="item.text"
                         :value="item.endTime">
              </el-option>
            </el-select>
          </div>
          <div class="tect_bdf Choose_img">
            <i class="sl-cl">
              <img src="../../assets/detection/image/icon-sc-1.png"
                   alt="">
            </i>
            已监测时长:<span class="tectedura">{{monitoringTime}}个月</span>
          </div>
        </div>
      </div>
      <div class="foot_x">
        <div class="sideSelect">
          <div class="SelectGrade">
            <div class="Choose_img">
              <i class="uble-cl">
                <img src="../../assets/detection/image/icon-sge-1.png"
                     alt="">
              </i>
              选择年级
            </div>
            <el-select @change="editGrade"
                       v-model="getGradeId"
                       placeholder="请选择">
              <el-option v-for="item in gradelList"
                         :key="item.newGradeId || item.id"
                         :label="item.gradeName"
                         :value="item.newGradeId">
              </el-option>
            </el-select>
          </div>
          <div class="SelectGrade">
            <div class="Choose_img">
              <i class="uble-class">
                <img src="../../assets/detection/image/icon-class.png"
                     alt="">
              </i>
              选择班级
            </div>
            <el-select @change="editClass"
                       v-model="getClassId"
                       placeholder="请选择">
              <el-option v-for="item in schoolClassList"
                         :key="item.isClass"
                         :label="item.isClass"
                         :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="offside_z"
             v-show="pleber">
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/icon-testNumbs.png"
                   alt="" />
            </div>
            <p style="color: #0E9CFF;">{{schoolRate.testNums}}</p>
            <span>已监测人数</span>
          </div>
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/icon-NumberOfPeopleWithVisualAbnormalities.png"
                   alt="" />
            </div>
            <p style="color: #FB9467;">{{schoolRate.abnormalNums}}</p>
            <span>视力异常人数</span>
          </div>
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/icon-VisualAcuityDiscrepancyRate.png"
                   alt="" />
            </div>
            <p style="color: #95C903;">{{AbnormalVisualVcuityRate}}</p>
            <span>视力异常率</span>
          </div>
        </div>
        <div class="pletobe"
             v-show="!pleber">
          <div class="NumberTested">
            <div class="bersd">
              <img src="../../assets/detection/image/renshu-1.png"
                   alt="" />
            </div>
            <p>{{NumberOfPeopleToBeTested}}</p>
            <span>待监测人数</span>
          </div>
        </div>
        <div class="offside_x">
          <div class="title_ser">
            <el-input placeholder="请输入学生姓名"
                      v-model="studentName"
                      @keyup.enter.native="studentSeach"
                      clearable>
            </el-input>
            <el-button type="primary"
                       @click="studentSeach"> 搜索<span class="gese"></span> <i class="el-icon-search el-icon--right"></i></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'
import namicMonitoring from '@/globals/service/management/monitoring.js'
import GradeClassService from '@/globals/service/management/GradeClass.js'
import studentService from '@/globals/service/management/student.js'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
export default {
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data () {
    return {
      maxHeight: document.documentElement.clientHeight,
      roleType: '',
      activeName: 'first',
      userSchoolName: '',
      formInline: {
        // user: '',
        region: ''
      },
      timeTypeVoList: [],
      MonigementInitRadio: 0, // 默认半月
      tableLoading: false,
      radio2: '',
      input: '',
      bertes: true, // 选择全校还是全班
      value2: '', // 月
      helfyell: false,
      roleTypeText: '',
      routerList: [],
      schoolList: '',
      gradelList: [],
      schoolClassList: '',
      getSchoolId: '',
      getGradeId: '',
      monitoringTime: '',
      getClassId: '',
      childrenList: [],
      showClass: false,
      startTime: '',
      endTime: '',
      schoolId: '',
      pageSize: 20,
      schoolData: {},
      startPage: 1,
      studentName: '',
      MonitoringChildrenVoListTotalPage: '',
      routeParams: '',
      schoolRate: {
        testNums: 0,
        abnormalNums: 0,
        abnormalRate: 0
      }
    }
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },
  created () {
    console.log(this.$route.params)
    this.$route.params.item && (this.routeParams = this.$route.params.item)

    const schoolId = storage.get('schoolId') || ''
    this.schoolId = schoolId
    this.roleType = Number(storage.get('roleType')) || null
    if (this.roleType === 10 || this.roleType === 7) {
      namicMonitoring.findBySchoolId({ schoolId: this.schoolId }).then(res => {
        this.userSchoolName = res.data.schoolName
      })
    }
    this.init()
  },
  mounted () {
    this.roleType = Number(storage.get('roleType')) || null
    console.log('this.roleType', this.roleType)
  },
  computed: {
    noTotalPage () {
      return this.startPage > this.MonitoringChildrenVoListTotalPage
    },
    tableLoadingDisabled () {
      return this.noTotalPage || this.tableLoading
    },
    MonitoringType () {
      return this.activeName === 'second' ? 0 : 1
    },
    pleber () { // 选择已未监测
      return this.activeName !== 'second'
    },
    NumberOfPeopleWithVisualAbnormalities () {
      // 视力异常人数
      return (this.schoolData.mildMyopiaNums + this.schoolData.moderateMyopiaNums + this.schoolData.severeMyopiaNums)
    },
    AbnormalVisualVcuityRate () {
      // 视力异常率
      return this.schoolRate.abnormalRate !== null && this.schoolRate.abnormalRate !== 0 ? (this.schoolRate.abnormalRate * 100).toFixed(2) + '%' : 0
    },
    // 待监测人数
    NumberOfPeopleToBeTested () {
      return isNaN(this.schoolRate.totalNums - this.schoolRate.testNums) ? 0 : this.schoolRate.totalNums - this.schoolRate.testNums
    }
  },
  methods: {
    //   选择半月份
    helfChange (value) {
      console.log('value', value)
      this.MonigementInitRadio = value
      this.getTimeType().then(res => {
        this.startTime = res.data.timeTypeVoList[0].startTime
        this.endTime = res.data.timeTypeVoList[0].endTime
        // 获取监测列表
        this.getMonigementInit()
      })
      if (value === 3) {
        this.helfyell = true
      } else {
        this.helfyell = false
      }
    },
    timeChange (value) {
      this.endTime = value
      this.timeTypeVoList.forEach(item => {
        console.log('item', item)
        if (item.endTime === value) {
          this.startTime = item.startTime
        }
      })
      this.getMonigementInit()
    },
    handleScroll (val) {
      console.log('val', val)
      this.show.startPage += 1
      this.show.pageSize += this.show.pageSize
      console.log(this.show.startPage, this.show.pageSize, '12')
    },
    //  获取时间
    getTimeType () {
      return new Promise((resolve, reject) => {
        namicMonitoring.anbanMonit({
          schoolId: this.schoolId,
          timeType: this.MonigementInitRadio,
          startPage: 1,
          pageSize: 10,
          loginKey: storage.get('loginKey'),
          uid: storage.get('uid')
        }).then(res => {
          console.log('res', res)
          this.timeTypeVoList = res.data.timeTypeVoList
          this.monitoringTime = res.data.monitoringTime
          this.value2 = res.data.timeTypeVoList[0].endTime
          resolve(res)
        })
      })
    },
    handleClick (tab, event) {
      console.log(tab, event)
      // if (this.activeName === 'second') {
      //   this.pleber = false
      // } else {
      //   this.pleber = true
      // }
      // 点击切换是否监测按钮  页数归1
      this.startPage = 1
      this.getMonigementInit('start')
    },
    init () {
      // 1、先获取年级/学校
      this.startPage = 1
      this.getSchoolGradeInit().then(res => {
        // 默认选择第一个年级/学校 or 传入的学校id
        if (res.data.length <= 0) { // 若切换时，获取年级为空，则break，且把其他数据置空，
          this.getGradeId = ''
          this.getClassId = ''
          this.schoolClassList = []
          this.gradelList = []
          this.childrenList = []
          // this.GetTime()
          return false
          // return this.$message.error('暂无数据')
        }
        if (this.roleType === 7) {
          this.getGradeId = this.routeParams.newGradeId || res.data[0].newGradeId
        } else {
          this.getGradeId = ''
        }
        // console.log('this.getGradeId', this.getGradeId)
        // 2、获取班级
        this.getNewGradeClassInit().then(GradeClassRes => {
          // 选择框也是第一个
          if (this.roleType === 7) {
            this.getClassId = GradeClassRes.data.classVoList[0].id
          } else {
            this.getClassId = ''
          }
          // 3、获取监测列表
          this.getMonigementInit()
          this.getSchoolRateInit()
        })
      })
    },

    // 获取学校/年级
    getSchoolGradeInit () {
      if (this.schoolId === '') {
        return new Promise((resolve, reject) => {
          namicMonitoring.getSchoolList({ startPage: 1, pageSize: 1000 }).then(res => {
            this.schoolList = res.data.schoolList
            // 下拉框默认一个选项
            this.getSchoolId = res.data.schoolList[0].id
            // 测试角色无scchoolId,默认学校列表第一个
            if (this.$route.params.schoolId) {
              this.schoolId = this.$route.params.schoolId
              this.getSchoolId = this.$route.params.schoolId
            } else {
              this.getSchoolId = res.data.schoolList[0].id
              this.schoolId = res.data.schoolList[0].id
            }
            this.getgetSchoolGradeInit().then(res => {
              resolve(res)
            }).catch(err => {
              reject(err)
            })
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          this.getgetSchoolGradeInit().then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取年级
    getgetSchoolGradeInit () {
      return new Promise((resolve, reject) => {
        // 获取时间
        this.getTimeType().then(res => {
          this.startTime = res.data.timeTypeVoList[0].startTime
          this.endTime = res.data.timeTypeVoList[0].endTime
          namicMonitoring.getSchoolGrade({
            schoolId: this.schoolId,
            newGradeId: this.getGradeId,
            schoolClassId: this.getClassId,
            timeType: this.MonigementInitRadio,
            startTime: this.startTime,
            endTime: this.endTime,
            uid: this.uid,
            loginKey: this.loginKey
          }).then(res => {
            this.gradelList = res.data
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      })
    },
    // 获取班级
    getNewGradeClassInit () {
      return new Promise((resolve, reject) => {
        GradeClassService.getNewGradeClass({
          schoolId: this.schoolId,
          newGradeId: this.getGradeId,
          startPage: 1,
          pageSize: 10,
          loginKey: storage.get('loginKey'),
          uid: storage.get('uid')
        }).then(res => {
          this.schoolClassList = res.data.classVoList
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getTeacherNewGradeClassInit () {
      return new Promise((resolve, reject) => {
        studentService.getClassId({
          schoolId: this.schoolId,
          newGradeId: this.getGradeId,
          startPage: 1,
          pageSize: 10,
          loginKey: storage.get('loginKey'),
          uid: storage.get('uid')
        }).then(res => {
          this.schoolClassList = res.data
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取学校数据
    getTestNumsInit () {
      return new Promise((resolve, reject) => {
        namicMonitoring.getTestNums({
          schoolId: this.schoolId,
          newGradeId: this.getGradeId,
          schoolClassId: this.getClassId,
          timeType: Number(this.MonigementInitRadio),
          startTime: this.startTime,
          endTime: this.endTime,
          loginKey: this.loginKey,
          uid: this.uid
        }).then(res => {
          console.log('学校数据', res)
          this.schoolData = res.data
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取监测列表
    // value = agrin 无线滚动  value = start 初始话加载，页数值为1
    getMonigementInit (value) {
      this.tableLoading = true
      this.getSchoolRateInit()
      if (value === 'start') {
        this.startPage = 1
      }
      this.getSchoolRateInit()
      return new Promise((resolve, reject) => {
        namicMonitoring.getMonigement({
          schoolId: this.schoolId,
          newGradeId: this.getGradeId, // 年级id
          schoolClassId: this.getClassId, // 班级id
          timeType: this.MonigementInitRadio, // 0半月；1月
          startTime: this.startTime,
          endTime: this.endTime,
          type: this.MonitoringType, // 0待监测 1已监测
          startPage: this.startPage,
          pageSize: this.pageSize
        }).then(res => {
          console.log('监测列表', res)
          this.MonitoringChildrenVoListTotalPage = res.data.totalPage
          if (value !== 'agrin') {
            this.childrenList = res.data.monitoringChildrenVoList
          } else {
            this.childrenList = this.childrenList.concat(res.data.monitoringChildrenVoList)
          }
          this.tableLoading = false
          resolve(res)
        }).catch(err => {
          this.tableLoading = false
          reject(err)
        })
      })
    },
    getSchoolRateInit () {
      return new Promise((resolve, reject) => {
        namicMonitoring.getSchoolRate({
          schoolId: this.schoolId,
          newGradeId: this.getGradeId, // 年级id
          schoolClassId: this.getClassId, // 班级id
          timeType: this.MonigementInitRadio, // 0半月；1月
          startTime: this.startTime,
          endTime: this.endTime,
          type: this.MonitoringType // 0待监测 1已监测
        }).then(res => {
          console.log('监测列表', res)
          this.schoolRate = res.data
          resolve(res)
        }).catch(err => {
          reject(err)
        }
        )
      })
    },
    tableLoad () {
      this.startPage += 1
      console.log('触发滚动', this.startPage, !this.tableLoadingDisabled)
      this.getMonigementInit('agrin')
    },
    getClassList () {
      const teacherClassId = storage.get('teacherClassId')
      if (!teacherClassId) {
        this.showClass = false
      }
    },

    editSchool (value) {
      this.schoolId = value
      this.init('PresenceSchool')
    },
    // 切换年级
    editGrade (value) {
      this.newGradeId = value
      // 获取班级
      if (this.roleType === 7) {
        this.getTeacherNewGradeClassInit().then(GradeClassRes => {
          this.getClassId = GradeClassRes.data.classVoList[0].classId
          this.getMonigementInit()
        })
      } else {
        this.getNewGradeClassInit().then(GradeClassRes => {
          this.getClassId = GradeClassRes.data.classVoList[0].id
          this.getMonigementInit()
        })
      }
      console.log('value', value)
    },
    // 选择班级
    editClass (value) {
      this.getClassId = value
      this.getMonigementInit()
    },
    formAmetropiaRate () { },
    formAbnormalRate () { },
    formNull () { },
    autoRoll (stop) {
      //   if (rolltimer) {
      //     clearInterval(rolltimer)
      //   }
      //   if (stop) {
      //     return
      //   }
      //   // 拿到表格挂载后的真实DOM
      //   const table = this.$refs.rw_table
      //   if (!table) return
      //   // 拿到表格中承载数据的div元素
      //   const divData = table.bodyWrapper
      //   // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果
      //   rolltimer = setInterval(() => {
      //     // 元素自增距离顶部像素
      //     // divData.scrollTop += this.rollPx
      //     divData.scrollTop += this.rollPx
      //     // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      //     if (divData.clientHeight + divData.scrollTop === divData.scrollHeight) {
      //       // 重置table距离顶部距离
      //       divData.scrollTop = 0
      //     }
      //   }, this.rollTime * 10)
    },
    handleSelectionChange (value) {
      console.log('value', value)
      // this.childrenListSelection = value
    },
    rowClass ({ row, rowIndex }) {
      // if (this.selectRow.includes(row.childrenId)) {
      if (rowIndex % 2 === 0) {
        return { 'text-align': 'center' }
      } else {
        return {
          'background-color': 'rgba(243, 245, 245, 1)',
          'text-align': 'center'
        }
      }
    },
    visionData1 (row) {
      if (row.leftVisionData === '-' && row.rightVisionData === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.leftVisionData) {
          left = row.leftVisionData
        }
        if (row.rightVisionData) {
          right = row.rightVisionData
        }
        return right + ' / ' + left
      }
    },
    visionData2 (row) {
      // return row.wearRightVisionData + ' / ' + row.wearLeftVisionData
      if (row.wearLeftVisionData === '-' && row.wearRightVisionData === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.wearLeftVisionData) {
          left = row.wearLeftVisionData
        }
        if (row.wearRightVisionData) {
          right = row.wearRightVisionData
        }
        return right + ' / ' + left
      }
    },
    screeningNameData (row) {
      return row.testNums + '/' + row.totalTestNums
    },
    // 搜索按钮
    studentSeach () {
      if (this.studentName === '') {
        return false
      }
      this.startPage = 1
      namicMonitoring.getMonitoringChildren({
        schoolId: this.schoolId,
        newGradeId: this.getGradeId, // 年级id
        schoolClassId: this.getClassId, // 班级id
        timeType: this.MonigementInitRadio, // 0半月；1月
        startTime: this.startTime,
        endTime: this.endTime,
        childrenName: this.studentName,
        type: this.MonitoringType // 0待监测 1已监测
      }).then(res => {
        console.log('监测列表', res)
        if (res.data.length === 0) {
          return this.$message({
            message: '没有查询到相关数据',
            type: 'warning'
          })
        }
        if (res.data[0].leftVisionData === null || res.data[0].rightVisionData === null) {
          this.activeName = 'second'
        } else {
          this.activeName = 'first'
        }
        this.childrenList = res.data

        // this.childrenList.push(res.data)
      })
    },
    resetStudentSeach () {
      this.getMonigementInit()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-select {
  width: 100%;
}
/deep/ .el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: white;
  z-index: 1;
  height: 0;
}
// /deep/ .el-radio {
//   //从这里开始 就是更改颜色的代码，将你的颜色           改掉我的颜色就可以了
//   .el-radio__label {
//     color: #000 !important;
//   }
//   .el-radio__input {
//     margin-bottom: px(5);
//     &.is-checked {
//       .el-radio__inner {
//         background-color: #28d4c1;
//         border-color: #28d4c1;
//       }
//     }
//     .el-radio__inner {
//       &:hover {
//         border-color: #28d4c1;
//       }
//     }
//   }
// }

/deep/.table_header_class th {
  background: #e1f5fc;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
}
.monitoringManagement {
  color: #000;
  //   overflow-y: auto;
  min-height: 100%;
  // height: 100%;
  width: 100%;
  // min-width: 1680px;
  background-color: #f1f6ff;
  padding: 30px 40px 0 40px;
  //   display: flex;
  //   flex-direction: column;
  display: flex;
  align-content: space-between;
  // overflow: auto;
  min-width: 1200px;
  // .Kanban{
  //     width: 100%;
  //     height: 100%;
  // }

  .toringMe {
    // height: 800px;
    // height: 825px;
    min-height: 100%;
    margin-bottom: 20px;
    min-width: 700px;
    width: 70%;
    border-radius: 10px;
    background-color: #fff;
    margin-right: 30px;
    // padding-left: 28px;
    box-shadow: 4px 4px 10px 1px rgba(215, 229, 255, 0.5),
      -4px -4px 10px 1px #d7e5ff;
    border-radius: 8px 8px 8px 8px;
    .torinat {
      width: 100%;
      height: 55px;
      line-height: 53px;
      padding-left: 28px;
    }
    .monitor {
      .el-tabs {
        padding-left: 28px;
      }
    }
  }
}
.ChooseSchool {
  /deep/.el-input__inner {
    background: rgba(52, 187, 236, 1);
    color: rgba(255, 255, 255, 1);
  }
  /deep/.el-select__caret {
    color: rgba(255, 255, 255, 1);
  }
}
.offside {
  position: relative;
  width: 406px;
  height: 736px;
  border-radius: 10px;
  //   height: 100%;
  background-color: #fff;
  padding: 24px 40px 0;
  .offside_s {
    height: 250px;
    // width: 406px;
    .el-radio-group {
      margin-top: 22px;
      margin-bottom: 30px;
    }
    .el-radio:nth-child(2) {
      margin-left: 80px;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 150px;
    }
    .nitorhe {
      height: 100%;
    }
    .block_s {
      width: 326px;
      //   height: 100%;
      margin-top: 20px;
      padding-bottom: 21px;
      border-bottom: 1px solid #ececec;
      .nyler {
        display: flex;
        //   align-content: start;
        .demo-form-inline {
          width: 150px;
          margin-left: 30px;
        }
      }
    }
    .ChooseSchool {
      ::v-deep .el-input {
        margin: 10px 0;
        height: 42px;
        border-radius: 4px;
        border: 0;
      }
    }
    .headerSchool {
      width: 326px;
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      padding-bottom: 14px;
      border-bottom: 1px solid #ececec;
      .header-link {
        width: 4px;
        height: 20px;
        background-color: #05baec;
        margin-right: 5px;
        border-radius: 8px;
      }
      .header-title {
        font-size: 20px;
      }
    }
  }
  .tect_bdf {
    // height: 60px;
    margin: 30px 0;
    .tectedura {
      margin-left: 10px;
      padding-bottom: 10px;
      color: #09a5de;
    }
  }
  .sideSelect {
    margin-top: 10px;
    .SelectGrade {
      width: 326px;
      ::v-deep .el-select {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 20px;
      }
    }
  }
  .pletobe {
    height: 127px;
    width: 125px;
    // line-height: 127px;
    // padding: 0 40px;
    position: absolute;
    // top: 244px;
    bottom: 126px;
    left: 40px;
    border-radius: 10px;
    font-size: 18px;
    box-shadow: 4px 4px 10px 1px #d7e5ff,
      -4px -4px 10px 1px rgba(215, 229, 255, 0.5);
    // padding: 0 20px;
    align-items: center;
    .NumberTested {
      width: 125px;
      height: 125px;
      margin-top: 15px;
      text-align: center;
      // border-right: 1px solid #ebebeb;
      .bersd {
        width: 21px;
        height: 21px;
        margin-left: 54px;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #0e9cff;
      }
      span {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .offside_z {
    height: 145px;
    width: 364px;
    // padding: 0 40px;
    position: absolute;
    // top: 244px;
    bottom: 110px;
    left: 21px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px 1px #d7e5ff,
      -4px -4px 10px 1px rgba(215, 229, 255, 0.5);
    display: flex;
    padding: 0 20px;
    font-size: 18px;
    // justify-content: space-evenly;
    align-items: center;
    .NumberTested {
      width: 133px;
      text-align: center;
      border-right: 1px solid #ebebeb;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      .bersd {
        width: 40px;
        height: 40px;
        // margin-left: 34px;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin-bottom: 10px;
      }
      span {
        font-size: 16px;
      }
    }
    .NumberTested:nth-child(3) {
      border-right: 0;
    }
    span {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .offside_x {
    height: 86px;
    margin-top: 180px;
    // height: 30px;
    // margin-top: 110px;
    .side-content {
      width: 220px;
      height: 220px;
      margin: 20px 56px;
    }
  }
  .side-header {
    .header-title {
      font-size: 18px;
      margin-top: 20px;
    }
  }
  .title_ser {
    display: flex;
    bottom: 30px;
    .gese {
      position: absolute;
      width: 1px;
      height: 16px;
      background-color: #fff;
      margin-left: 5px;
      margin-right: 5px;
    }
    ::v-deep .el-button--primary {
      position: relative;
    }
  }
}
.foot_def {
  width: 406px;
  height: 100%;
  // margin: 0 40px;
  .foot_s {
    padding: 0 40px;
    padding-top: 24px;
    height: 368px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 4px 4px 10px 1px rgba(199, 221, 240, 0.5),
      -4px -4px 10px 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px 8px 8px 8px;
    .el-radio-group {
      margin-top: 22px;
      margin-bottom: 30px;
    }
    .el-radio:nth-child(2) {
      margin-left: 80px;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 150px;
    }
    .nitorhe {
      height: 100%;
    }
    .block_s {
      width: 326px;
      margin-top: 20px;
      .nyler {
        display: flex;
        .demo-form-inline {
          width: 150px;
          margin-left: 30px;
        }
      }
    }
    .ChooseSchool {
      margin-bottom: 10px;
      ::v-deep .el-input {
        margin: 10px 0;
        height: 42px;
        border-radius: 4px;
        border: 0;
      }
    }
    .tect_bdf {
      margin-top: 16px;
      .tectedura {
        margin-left: 16px;
        padding-bottom: 38px;
        color: #09a5de;
      }
    }
  }
  .foot_x {
    // width: 406px;
    position: relative;
    padding: 0 40px ;
    margin-top: 30px;
    // height: 500px;
    margin-bottom: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 4px 4px 10px 1px rgba(199, 221, 240, 0.5),
      -4px -4px 10px 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px 8px 8px 8px;
    .offside_z {
      height: 145px;
      width: 364px;
      // padding: 0 40px;
      position: absolute;
      // top: 244px;
      bottom: 115px;
      left: 21px;
      border-radius: 10px;
      box-shadow: 4px 4px 10px 1px #d7e5ff,
        -4px -4px 10px 1px rgba(215, 229, 255, 0.5);
      display: flex;
      padding: 0 20px;
      font-size: 18px;
      // justify-content: space-evenly;
      align-items: center;
      .NumberTested {
        width: 133px;
        text-align: center;
        border-right: 1px solid #ebebeb;
        .bersd {
          width: 40px;
          height: 40px;
          margin-left: 34px;
          margin-bottom: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          margin-top: 10px;
        }
        span {
          font-size: 16px;
        }
      }
      .NumberTested:nth-child(3) {
        border-right: 0;
      }
      span {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .offside_x {
      height: 86px;
      margin-top: 180px;
      // height: 30px;
      // margin-top: 110px;
      .side-content {
        width: 220px;
        height: 220px;
        margin: 20px 56px;
      }
    }
    .title_ser {
      display: flex;
      bottom: 30px;
      .gese {
        position: absolute;
        width: 1px;
        height: 16px;
        background-color: #fff;
        margin-left: 5px;
        margin-right: 5px;
      }
      ::v-deep .el-button--primary {
        position: relative;
      }
    }
    .sideSelect {
      padding-top: 25px;
      .SelectGrade {
        width: 326px;
        ::v-deep .el-select {
          width: 100%;
          margin-top: 8px;
          margin-bottom: 20px;
        }
      }
    }
    .pletobe {
      height: 127px;
      width: 125px;
      position: absolute;
      bottom: 146px;
      left: 40px;
      border-radius: 10px;
      font-size: 18px;
      box-shadow: 4px 4px 10px 1px #d7e5ff,
        -4px -4px 10px 1px rgba(215, 229, 255, 0.5);
      align-items: center;
      .NumberTested {
        width: 125px;
        height: 125px;
        margin-top: 15px;
        text-align: center;
        .bersd {
          width: 21px;
          height: 21px;
          margin-left: 54px;
          margin-bottom: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          margin-top: 10px;
          margin-bottom: 10px;
          color: #0e9cff;
        }
        span {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
.Choose_img {
  display: flex;
  .uble-cl {
    margin-right: 6px;
    margin-top: -2px;
    width: 26px;
    height: 26px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .uble-class {
    margin-right: 6px;
    margin-top: 2px;
    width: 20px;
    height: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .sl-cl {
    margin-right: 6px;
    margin-top: 2px;
    width: 18px;
    height: 18px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
